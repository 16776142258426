
// ヘッダースクロールでサイズ調整
var _window = $(window),
  _header = $('#header'),
  heroBottom;

_window.on('scroll',function(){
  heroBottom = $('#header-content').height();
  if(_window.scrollTop() > heroBottom){
  _header.addClass('header-scroll');   
  }
  else{
  _header.removeClass('header-scroll');   
  }
});

_window.trigger('scroll');

// トップへ戻るボタン＆スムーススクロール
$("#page_top").hide();
$(window).on("scroll", function() {
    if ($(this).scrollTop() > 120) {
        $("#page_top").fadeIn();
    } else {
        $("#page_top").fadeOut();
    }
    scrollHeight = $(document).height(); //ドキュメントの高さ 
    scrollPosition = $(this).height() + $(this).scrollTop(); //現在地 
    footHeight = $("#footer").innerHeight(); //footerの高さ（＝止めたい位置）
    if ( scrollHeight - scrollPosition  <= footHeight) { //ドキュメントの高さと現在地の差がfooterの高さ以下になったら
        $("#page_top").css({
            "position":"absolute",
            "bottom": footHeight - 25
        });
    } else { //それ以外の場合は
        $("#page_top").css({
            "position":"fixed",
            "bottom": 50
        });
    }
});
$('#page_top').click(function () {
    $('body,html').animate({
    scrollTop: 0
    }, 400);
    return false;
});

// スマホメニューオープン
$('.sp-menu').click(function() {
    $(this).toggleClass('active');

    if ($(this).hasClass('active')) {
        $('.header-menu,#header').addClass('active');
    } else {
        $('.header-menu,#header').removeClass('active');
    }
});

// スクロール遅延表示
var fuwa1= {
  viewFactor: '0',
  delay: 1,
  duration: 1800,
  distance: '50px',
  origin: 'bottom',
},
fuwa2 = {
  delay: 1,
  duration: 2000,
  distance: '50px',
  origin: 'bottom',
},
fuwa3 = {
    viewFactor: '0',
    delay: 1600,
    duration: 1800,
    distance: '10px',
    origin: 'bottom',
},
fuwa4 = {
    delay: 1,
    duration: 2000,
    distance: '50px',
    origin: 'bottom',
    reset: 'true',
};

ScrollReveal().reveal('.slowly_mv', fuwa1);
ScrollReveal().reveal('.slowly', fuwa2);
ScrollReveal().reveal('.slowly_top', fuwa3);
ScrollReveal().reveal('.slowly_top2', fuwa4);
